<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">External Payment Provider Audit Login</h3>
          <div class="flex align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input ref="searchInput" v-model="keyword" aria-label="search" class="form-control ml-8 mr-8"
              placeholder="Search..." type="text" @keyup="search($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant</th>
                <th>External Payment Provider</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Order ID</th>
                <th>Payment ID</th>
                <th>Payment Method</th>
                <th>Reference</th>
                <th>Status</th>
                <th>Status Code</th>
                <th>Transfer ID</th>
                <th>Client Account</th>
                <th>Client Email</th>
                <th>Operation Date</th>
                <th>Operation Pay Date</th>
                <th>CheckSum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td class="no-wrap">
                    <router-link v-if="record.ShopTitle" :to="{ name: 'user-detail', params: { id: record.MerchantID } }"
                      tag="a">
                      {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                    </router-link>
                  </td>
                  <td class="no-wrap">
                    {{ record.ExternalPaymentProvider }}
                  </td>
                  <td>{{ record.TransactionID }}</td>
                  <td>{{ record.Amount }}</td>
                  <td class="no-wrap">{{ record.Currency }}</td>
                  <td class="no-wrap">{{ record.OrderID }}</td>
                  <td class="no-wrap">{{ record.PaymentID }}</td>
                  <td>{{ record.PaymentMethod }}</td>
                  <td>{{ record.Reference }}</td>
                  <td>{{ record.Status }}</td>
                  <td>{{ record.StatusCode }}</td>
                  <td>{{ record.TransferID }}</td>
                  <td class="no-wrap">{{ record.ClientAccount }}</td>
                  <td class="no-wrap">{{ record.ClientEmail }}</td>
                  <td class="no-wrap">
                    <span v-html="formatDate(record.OperationDate)"></span>
                  </td>
                  <td class="no-wrap">
                    <span v-html="formatDate(record.OperationPayDate)"></span>
                  </td>
                  <td>{{ record.Checksum }}</td>
                  <td class="action-sm pr-16"></td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="19">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";

export default {
  name: "Audit",
  components: {
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getPaginatedRecords(1);
        }, 500);
      }
    },
    getPaginatedRecords(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=audit`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.getPaginatedRecords();
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
